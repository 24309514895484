import React, { useEffect, useRef, useState } from 'react';
import FactCards from './FactCards';
import { compose } from 'recompose';
import { GQL_FETCH_FACT_CARDS, GQL_FETCH_CURRENT_APP_USER } from './graphql';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import { ElementOverlay } from '@manakin/app-core';
import { useHistory } from 'react-router-dom';
import { SettingsKeys } from '@manakin/core/lib/constants';

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	loader: {
		backgroundColor: 'white',
		position: 'absolute',
		width: '100vw',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
});

const maxPageSize = 6;

const FactCardsContainer = (props) => {
	const location = useLocation();
	const { state } = location || {};
	const history = useHistory();
	const [initialFilter, setInitialFilter] = useState([]);

	const factCardsRef = useRef();
	const [page, setPage] = useState(0);
	const [moreFactCardsAvailable, setMoreFactCardsAvailable] = useState(true);
	const [ factCards, setFactCards ] = useState([]);
	const [ filter, setFilter ] = useState({});

	// Queries
	const { loading: currentUserLoading, data: currentUserData } = useQuery(GQL_FETCH_CURRENT_APP_USER);
	const [getFactCards, { loading: factCardsLoading }] = useLazyQuery(GQL_FETCH_FACT_CARDS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			const { factCards : factCardsData = {} } = data || {};
			const { factCards: newFactCards = [] } = factCardsData
			const list = [...factCards].concat(newFactCards);
			setFactCards(list);
			setMoreFactCardsAvailable(list.length < factCardsData.count);
		}
	});
	const loading = factCardsLoading && page === 0;

	const handleLoadMore = () => {
		if (!currentUserLoading && !loading && moreFactCardsAvailable) {
			setPage(page + 1);
			handleFactCards(page + 1);
		}
	};

	const handleFilter = (data) => {
		if (data) {
			// Reset pagination
			setPage(0);
			setMoreFactCardsAvailable(true);
			setFactCards([]);

			setFilter({
				categories: data[SettingsKeys.FACT_CARD_FILTER_CATEGORIES],
				tags: data[SettingsKeys.FACT_CARD_FILTER_TAGS]
			});
		}
	};

	const getProductIds = () => {
		let productIds = {};
		if (currentUserData && currentUserData.currentAppUser && currentUserData.currentAppUser.licenses) {
			currentUserData.currentAppUser.licenses.forEach((license) => {
				productIds[license.product.id] = true;
				if (license.product.subProducts && license.product.subProducts.length) {
					license.product.subProducts.forEach((subProduct) => {
						productIds[subProduct.id] = true;
					});
				}
			});
		}

		return productIds;
	}

	useEffect(() => handleFactCards(), [filter]);

	const getTagIds = () => {
		if (filter.tags) {
			return filter.tags;
		 }
		 if (state) {
			return state.tags.map((tag) => tag.id);
		 }
		 return [];
	};

	const handleFactCards = (newPage) => {
		if (!currentUserLoading) {

			// Use filter if filter is set, fallback to state filtertags (these can be set on redirect to this page)
			const tagIdList = getTagIds(filter, state);

			getFactCards({
				variables: {
					page: newPage || page,
					// Tag order sorting is done locally, so we can't paginate
					pagesize: maxPageSize,
					filter: {
						categoryIds: filter.categories || null,
						tagIds: tagIdList,
						productIds: Object.keys(getProductIds()) || null,
					 }					 
				},
			});
		}
	}

	//effect hooks
	useEffect(() => {
		if (!currentUserLoading) {
			handleFactCards();
		}
	}, [currentUserData, currentUserLoading]);

	useEffect(() => {
		if (state && state.tags) {
			setInitialFilter(state.tags.map((i) => i.name), []);
		}
	}, [state]);

	const FactCardsElement = (
		<FactCards
			ref={factCardsRef}
			loading={loading}
			factCards={factCards}
			loadMore={handleLoadMore}
			onFilter={handleFilter}
			initialFilter={initialFilter} />
	);

	if (state && (state.fromPreview || state.fromLesson)) {
		return (
			<ElementOverlay fullWidth={true} controls={false} customControls={true} onClick={history.goBack}>
				<div ref={factCardsRef}>
					{FactCardsElement}
				</div>
			</ElementOverlay>
		);
	}

	return (
		<div ref={factCardsRef}>
			{FactCardsElement}
		</div>
	);
};

export default compose(withStyles(styles))(FactCardsContainer);
